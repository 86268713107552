import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-login-details',
  templateUrl: './login-details.component.html',
  styleUrls: ['./login-details.component.css']
})
export class LoginDetailsComponent implements OnInit {
  login_list: any;
  offset_count: any;
  paginationData: any;
  pageLimit =20;
  recordNotFound: boolean;

  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit(): void {
    this.get_list({});

  }
  listDataInfo(list_data){

		list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
		//list_data.order_by_name = list_data.order_by_name == undefined ? "history.report_details_id" : list_data.order_by_name;
		//list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
		list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
		list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
		return list_data;
	}



  get_list(data){
	Swal.fire({
		html:
			'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
	showCloseButton: false,
		showCancelButton: false,
		showConfirmButton: false,
		focusConfirm: false,
		background: 'transparent',
	});

			var list_data= this.listDataInfo(data);
			let api_req:any = new Object();
			let history_req:any = new Object();
			history_req.action="airliquid_loginlist";
			history_req.search_text=list_data.search_text;
			history_req.order_by_name=list_data.order_by_name;
			history_req.order_by_type=list_data.order_by_type;
			history_req.limit=list_data.limit;
			history_req.offset=list_data.offset;
			history_req.user_id=localStorage.getItem('userId');
			api_req.operation="airliquid_report";
			api_req.moduleType="airliquid_report";
			api_req.api_type="web";
			api_req.access_token=localStorage.getItem('access_token');
			api_req.element_data = history_req;
      this.serverService.sendServer(api_req).subscribe((response:any) => {
		Swal.close();
	            if(response.result.status==1){
	           
	            	this.login_list=response.result.data;
	            	this.offset_count = list_data.offset;
	            	this.paginationData = this.serverService.pagination({'offset':response.result.list_info.offset, 'total':response.result.list_info.total, 'page_limit' :this.pageLimit });
	            	this.recordNotFound = this.login_list.length == 0 ? true : false;
	            }
               
            }, 
            (error)=>{
                //console.log(error);
            });

	  }
  get_list1(){
  }

}
