import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
declare var Chart: any; // Declare Chart to avoid TypeScripterrors
declare var $: any;
declare var iziToast: any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-wallboard13',
  templateUrl: './wallboard13.component.html',
  styleUrls: ['./wallboard13.component.css']
})
export class Wallboard13Component implements AfterViewInit {
  queueData;
  has_hard_id;
  admin_id;
  show_act_wall = false;
  staticQ;

  @ViewChild('AgentChart') chartRef: ElementRef;
  @ViewChild('Queuechart') QueueRef: ElementRef;

  @ViewChild('chartContainer') chartContainer: ElementRef;
  // ChartArray: any = [20, 40, 40],[10,90, 0]

  ChartArray: any = []

  socket_url: string;
  user_type: string;
  admin_permision: string;
  extension: string;
  websocket: WebSocket;
  queueData1: any;
  quesData: any;
  pieQueues: any;
  canvase_new_val: any;
  constructor() { }

  ngAfterViewInit(): void {

    this.socket_url = localStorage.getItem('socket_url');
    this.admin_id = localStorage.getItem('admin_id');
    this.admin_permision = localStorage.getItem('admin_permision');
    // this.has_hard_id = localStorage.getItem('hardware_id');
    this.has_hard_id = 'BF20-79D9-3A87-97B4-82FF-1225-825A-9951';

    if (this.has_hard_id == "") {
      $("#addLicence").modal({ "backdrop": "static" });
      this.show_act_wall = true;
    } else {
      this.initSocket();
    }
    // this.createPieCharts();
    this.Agent1();
    this.Agent2();
    // this.samplesocket()
  }

  initSocket() {
    // this.has_hard_id = localStorage.getItem('hardware_id');
    this.has_hard_id = 'BF20-79D9-3A87-97B4-82FF-1225-825A-9951';
    var self = this.admin_id;

    // this.websocket = new WebSocket("wss://"+atob(this.socket_url)+":4010");
    // this.websocket = new WebSocket("wss://c4cteams.my3cx.sg:4010");
    // this.websocket = new WebSocket("wss://developtesting.my3cx.sg:4010");
    // this.websocket = new WebSocket("wss://omnisales.my3cx.sg:4010");
    // this.websocket = new WebSocket("wss://"+window.location.hostname+":4010");
    this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4001");


    this.websocket.onopen = function (event) {
      $('#sendonload_wallborad123').click();
      //console.log('custom walboard 1 connected');
    }
    this.websocket.onmessage = function (event) {
      console.log(event.data);
      var result_message = JSON.parse(event.data);
      // console.log(result_message);

      if (result_message[0].data[0].type != undefined) {
        $('#mrVoipType').val(event.data);
        $('#mrVoipType').click();
      }
      else if (result_message[0].data[0].exceed_sla != undefined) {
        $('#mrVoipQData').val(event.data);
        $('#mrVoipQData').click();
      }
      // else if (result_message[0].data[0].qno != undefined) {
      //   $('#mrVoipQData').val(event.data);
      //   $('#mrVoipQData').click();
      // }
      else if (result_message[0].data[0].ano != undefined) {
        $('#mrVoipAData_wall').val(event.data);
        $('#mrVoipAData_wall').click();
      } else if (result_message[0].data[0].logged_queues != undefined) {
        $('#logged_queues').val(event.data);
        $('#logged_queues').click();
      } else if (result_message[0].data[0].queue_name != undefined) {
        $('#ques_list').val(event.data);
        $('#ques_list').click();
      } else if (result_message[0].data[0].agent_name != undefined) {
        $('#agent_list').val(event.data);
        $('#agent_list').click();
      }
      else if (result_message[0].data[0].single_agent != undefined) {
        $('#single_agent').val(event.data);
        $('#single_agent').click();
      }
      else if (result_message[0].data[0].single_queue != undefined) {
        $('#single_queue').val(event.data);
        $('#single_queue').click();
      }

      if (result_message[0].data[0].q_name != undefined) {

        if (result_message[0].data[0].q_name != "" && result_message[0].data[0].q_name != null) {
          $('#Qcalldatas_wall').val(event.data);
          $('#Qcalldatas_wall').click();
          $('#recordNotFound').val('check');
          $('#recordNotFound').click();
        }
        else {
          $('#recordNotFound').val('');
          $('#recordNotFound').click();
          $('#Qcalldatas_wall').val();
          // $('#Qcalldatas_wall').load();
        }
      }

    }
    this.websocket.onerror = function (event) {
      //console.log('error');
    }
    this.websocket.onclose = function (event) {
      //console.log('Socket Disconnected.Reconnection will be attempted');
      $('#reconnect_socketwal').click();
      // this.initSocket();
    }

  }

  sendOnload() {
    var socket_message = '[{"cust_id":"' + this.has_hard_id + '","data":[{"Name":"onload","Type":"advance_wallboard_1"}]}]';
    this.websocket.send(socket_message);
  }

  changeData() {
    let socketData = $('#ques_list').val();
    let mData = JSON.parse(socketData);
    this.queueData = mData[0].data;
    // console.log('not-ok',this.queueData)
    // const queueNumbers = [];
    // this.queueData.forEach((item) => {
    //   queueNumbers.push(item.queue_number);
    //   const commaSeparatedNumbers = queueNumbers.join(', ');
    //   console.log("Queue Numbers: " + commaSeparatedNumbers);
    // })

  }

  changeQuesData() {
    let socketData = $('#mrVoipQData').val();
    let mData = JSON.parse(socketData);
    this.quesData = mData[0].data;
//
    var whole_array_value = []
    this.quesData.forEach(item => {
      whole_array_value.push(item.agentonline);
      whole_array_value.push(item.agentbusy);
      whole_array_value.push(item.agentlogout);
    });
    const wholeNumberValues = whole_array_value.map(value => {
      return parseInt(value, 10);
    });
    console.log('whole_array_value', wholeNumberValues);
    const chunkSize = 3;
    const splitArrays = [];
    for (let i = 0; i < wholeNumberValues.length; i += chunkSize) {
      splitArrays.push(wholeNumberValues.slice(i, i + chunkSize));
    }
    this.canvase_new_val = splitArrays;
    console.log(this.canvase_new_val);
    console.log('split', splitArrays);

    // const item = this.quesData[0]; // Assuming this.quesData[0] contains your object
    // const item = this.quesData
    // const agentOnlineValue = item.agentonline;
    // const agentBusyValue = item.agentbusy;
    // const agentLogoutValue = item.agentlogout;
    // const agentValues = [agentOnlineValue, agentBusyValue, agentLogoutValue];
    // var wholeNumbers = agentValues.map(percentage => {
    //   const percentageValue = parseFloat(percentage.replace('%', ''));
    //   return Math.round(percentageValue); // Round to the nearest whole number
    // });
    // console.log('wholeNumbers', wholeNumbers)
    // for (let i = 0; i < wholeNumbers.length; i++) {
    //   this.ChartArray = [];
    //   this.ChartArray[i] = wholeNumbers[i];
    // }
    // this.ChartArray.push(...wholeNumbers);
    this.Agent1();
    // console.log('wholeNumber', wholeNumbers)
    console.log('update Q', this.quesData)
  }


  Agent1() {
    console.log('!', this.ChartArray);
    const ctx = this.chartRef.nativeElement.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ["QueueOnline", "QueueBusy", "QueueLogut"],
        datasets: [{
          backgroundColor: ["#6be5ea", "#41b8d6", "#2c8bb9",],
          data: this.ChartArray
          // data: [40, 20, 40]
        }]
      }
    });

  }

  Agent2() {
    const ctx = this.QueueRef.nativeElement.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ["QueueOnline", "QueueBusy", "QueueLogut"],
        datasets: [{
          backgroundColor: ["#6be5ea", "#41b8d6", "#2c8bb9",],
          data: this.ChartArray
          // data: [40, 20, 40]
        }]
      }
    });
  }

  // createPieCharts() {
  //   for (const entry of this.mainQueues) {
  //     const ctx = this.chartRef.nativeElement.getContext('2d');
  //     const data = entry.data;
  //     const labels = data.map(item => item.QName);
  //     const values = data.map(item => item.no);

  //     const myChart = new Chart(ctx, {
  //       type: 'pie',
  //       data: {
  //         labels: labels,
  //         datasets: [{
  //           backgroundColor: ["#6be5ea", "#41b8d6", "#2c8bb9"],
  //           data: values
  //         }]
  //       }
  //     });
  //   }
  // }

  addQueue() {
    $('#addQueue').modal('show');
  }

  getQueues() {
    var mainQueues = $('.queues_check:checked').map(function () {

      return this.value;
    }).get();
    if (mainQueues == '') {
      iziToast.warning({
        message: "Please Select Atleast One",
        position: 'topRight'
      });
      return false;
    }
    // this.has_hard_id = localStorage.getItem('hardware_id');
    this.has_hard_id = 'BF20-79D9-3A87-97B4-82FF-1225-825A-9951';
    mainQueues = '[{"cust_id":"' + this.has_hard_id + '","data":[' + mainQueues.join() + ']}]';
    // this.pieQueues=mainQueues
    console.log('mainQueues', mainQueues)
    this.websocket.send(mainQueues);
    $('#addQueue').modal('hide');
  }

  slectAllQue() {
    if ($("#selectAllQ").prop("checked")) {
      $(".queues_check").prop("checked", true);
    } else {
      $(".queues_check").prop("checked", false);
    }
  }
  slectuniqueque() {
    $("#selectAllQ").prop("checked", false);
  }

}

