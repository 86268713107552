import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $: any;
declare var iziToast: any;
import Swal from 'sweetalert2'

@Component({
  selector: 'app-agent-summery-report',
  templateUrl: './agent-summery-report.component.html',
  styleUrls: ['./agent-summery-report.component.css']
})
export class AgentSummeryReportComponent implements OnInit {
  getRep: FormGroup;
  call_history_list: any;
  tot_data: any;
  total_name: any;
  percent: any;
  summery_list: any;
  res: any;
 
  constructor(private serverService: ServerService, private router: Router) { }

  ngOnInit() {
    this.getRep = new FormGroup({
      'from_date': new FormControl(null),
      'to_date': new FormControl(null),
    });
    this.searchData();

  }



  searchData() {

    // if(this.getRep.value.from_date == null||this.getRep.value.to_date ==null){
    //   iziToast.warning({
    //     message: "Please Select from to Date to do search",
    //     position: 'topRight'
    // });
    // return false;
    // }
    Swal.fire({
      html:
        '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
    showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      background: 'transparent',
    });

    let api_req: any = new Object();
    let history_req: any = new Object();
    history_req.action = "airliquidsummary_report";
    history_req.from_dt = '';
    history_req.to_dt = '';
    api_req.operation = "airliquid_report";
    api_req.moduleType = "airliquid_report";
    api_req.api_type = "web";
    api_req.access_token = localStorage.getItem('access_token');
    api_req.element_data = history_req;
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      Swal.close();
      //console.log(response);
      this.summery_list = response.result.data;
      this.tot_data = response.nos;
      this.total_name = response.total;
      this.percent = response.percentage;

    },
      (error) => {
        //console.log(error);
      });

  }

  searchData1() {

    let from_date = $('#from_date').val();
    let to_date = $('#to_date').val();

  if(from_date == null ||from_date == ''){
    iziToast.warning({
      message: "Please fill From Date",
      position: 'topRight'
  });
  
  return false;
  }

  var currentDate = new Date(); 
  var selectedToDate = new Date(to_date); 
  var selectedToDate1 = new Date(from_date); 

if (to_date == null || to_date == '' || selectedToDate > currentDate || selectedToDate1 > currentDate ) {
iziToast.warning({
  message: "Please Select a Valid Date",
  position: 'topRight'
});
return false;
}
Swal.fire({
  html:
    '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
showCloseButton: false,
  showCancelButton: false,
  showConfirmButton: false,
  focusConfirm: false,
  background: 'transparent',
});
    let api_req: any = new Object();
    let history_req: any = new Object();
    history_req.action = "airliquidsummary_report";
    history_req.from_dt = this.getRep.value.from_date;
    history_req.to_dt = this.getRep.value.to_date;
    api_req.operation = "airliquid_report";
    api_req.moduleType = "airliquid_report";
    api_req.api_type = "web";
    api_req.access_token = localStorage.getItem('access_token');
    api_req.element_data = history_req;
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      Swal.close();
      this.summery_list = response.result.data;
      this.tot_data = response.nos;
      this.total_name = response.total;
      this.percent = response.percentage;
      this.getRep.reset();

    },
      (error) => {
        //console.log(error);
      });

  }






  getReports() {
    let api_req: any = new Object();
    let agents_req: any = new Object();
    agents_req.action = "airliquidsummary_report";
    agents_req.from_dt = this.getRep.value.from_date;
    agents_req.to_dt = this.getRep.value.to_date;
    api_req.operation = "airliquid_report";
    api_req.moduleType = "airliquid_report";
    api_req.api_type = "web";
    api_req.access_token = localStorage.getItem('access_token');
    api_req.element_data = agents_req;
    //console.log(api_req);
    // if(this.getRep.value.from_date == null||this.getRep.value.to_date ==null || this.getRep.value.to_date < this.getRep.value.from_date){
    //   iziToast.warning({
    //     message: "Please Select From/To Date",
    //     position: 'topRight'
    // });
    // return false;
    // }
let from_date = $('#from_date').val();
let to_date = $('#to_date').val();

  if(from_date == null ||from_date == ''){
    iziToast.warning({
      message: "Please fill From Date",
      position: 'topRight'
  });
  
  return false;
  }

  var currentDate = new Date(); 
  var selectedToDate = new Date(to_date); 
  var selectedToDate1 = new Date(from_date); 

if (to_date == null || to_date == '' || selectedToDate > currentDate || selectedToDate1 > currentDate ) {
iziToast.warning({
  message: "Please Select a Valid Date",
  position: 'topRight'
});
return false;
}
    Swal.fire({
      html:
        '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
    showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      background: 'transparent',
    });
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      //console.log(response);
      Swal.close();
      if ( response.result.data.length!==0) {
        this.res = response;
        var arrStr = encodeURIComponent(JSON.stringify(this.res));
        // document.location.href = 'https://hp.mconnectapps.com/api/storage/contact/download.php?res='+arrStr;
  
  
        var url = 'https://airliquidethai.mconnectapps.com/api/storage/report/airthai_Summary_report.php';
        var form = $('<form target="_blank" action="' + url + '" method="post">' +
          '<input type="text" name="res" value="' + arrStr + '" />' +
          '</form>');
        $('body').append(form);
        form.submit();
        this.getRep.reset();
      } else {
        iziToast.warning({
          message: "No Records Found. Please try again",
          position: 'topRight'
        });
      }
     


    },
      (error) => {
        //console.log(error);
      });
  }








  


}


