import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;
declare var iziToast: any;
import Swal from 'sweetalert2'

@Component({
  selector: 'app-agent-map',
  templateUrl: './agent-map.component.html',
  styleUrls: ['./agent-map.component.css']
})
export class AgentMapComponent implements OnInit {

  agentmap_list: any;
  offset_count: any;
  paginationData: any;
  pageLimit =20;
  recordNotFound: boolean;
  add_agentmaping: FormGroup;
  edit_agentmaping: FormGroup;
  edit_agentmap_id;

  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit(): void {
    this.getagentmap_list({});
    this.add_agentmaping = new FormGroup({
      'dins': new FormControl(null, Validators.required),
      'agent_name': new FormControl(null),
    });
    this.edit_agentmaping = new FormGroup({
      'dins': new FormControl(null, Validators.required),
      'agent_name': new FormControl(null),
    });

  }
  listDataInfo(list_data){

		list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
		//list_data.order_by_name = list_data.order_by_name == undefined ? "history.report_details_id" : list_data.order_by_name;
		//list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
		list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
		list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
		return list_data;
	}
  addDepartment() {
    this.add_agentmaping.reset();
    $('#add_agentmaping').modal('show');
  }
  add_agent_map(){

    let agent_req: any = this.add_agentmaping.value;
    let access_token: any = localStorage.getItem('access_token');

    let api_req: any = '{"operation":"airliquid_report", "moduleType": "airliquid_report", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"add_agentMap","dins":"' + agent_req.dins + '","agent_name":"' + agent_req.agent_name + '"}}';

    this.serverService.sendServer(api_req).subscribe((response: any) => {
      this.add_agentmaping.reset();
      if (response.data == '1') {
        $('#add_agentmaping').modal('hide');
        iziToast.success({
          message: "Agent Map added successfully",
          position: 'topRight'
        });
        this.getagentmap_list({});
      }
      else if (response.data == '2') {
        iziToast.warning({
          message: "DINS already Exist",
          position: 'topRight'
        });
      }
      else {

        iziToast.error({
          message: "Agent Map not added. Please try again",
          position: 'topRight'
        });

      }

    },
      (error) => {
        iziToast.error({
          message: "Sorry, some server issue occur. Please contact admin",
          position: 'topRight'
        });
        //console.log(error);
      });



  }

  editagent_map(id) {
    let access_token: any = localStorage.getItem('access_token');
    let api_req: any = '{"operation":"airliquid_report", "moduleType": "airliquid_report", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"edit_agentMap","agent_map_id":"' + id + '"}}';

    this.serverService.sendServer(api_req).subscribe((response: any) => {
      // console.log(response)
      // console.log(response.result.status)
      if (response.result.status == true) {
        // console.log(response.result.data)
        var agent_data = response.result.data;
        this.edit_agentmaping.setValue({
          'dins': agent_data.dins,
          'agent_name': agent_data.agent_name,
        });
        this.edit_agentmap_id = response.result.data.agent_map_id;

        $('#edit_agentmaping').modal('show');
      } else {

        iziToast.warning({
          message: "Please try again",
          position: 'topRight'
        });

      }
    },
      (error) => {
        //console.log(error);
      });
  }
  edit_agent_map(id){


      let agent_req: any = this.edit_agentmaping.value;
      let access_token: any = localStorage.getItem('access_token');
      let api_req: any = '{"operation":"airliquid_report", "moduleType": "airliquid_report", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"update_agentMap","agent_map_id":"' + id + '","dins":"' + agent_req.dins+ '","agent_name":"' + agent_req.agent_name+ '"}}';
  
      this.serverService.sendServer(api_req).subscribe((response: any) => {
        if (response.data == 1) {
          $('#edit_agentmaping').modal('hide');
          iziToast.success({
            message: "Dins updated successfully",
            position: 'topRight'
          });
          this.getagentmap_list({});
        } else{
  
          iziToast.warning({
            message: "Dins Already Inserted. Please try again",
            position: 'topRight'
          });
  
        }
        // else {
  
        //   iziToast.warning({
        //     message: "Aux Code not updated. Please try again",
        //     position: 'topRight'
        //   });
  
        // }
  
      },
        (error) => {
          iziToast.error({
            message: "Sorry, some server issue occur. Please contact admin",
            position: 'topRight'
          });
          //console.log(error);
        });

  }


  getagentmap_list(data){
    Swal.fire({
      html:
        '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
    showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      background: 'transparent',
    });

			var list_data= this.listDataInfo(data);
			let api_req:any = new Object();
			let history_req:any = new Object();
			history_req.action="airliquid_AgentMaplistreport";
			history_req.search_text=list_data.search_text;
			history_req.limit=list_data.limit;
			history_req.offset=list_data.offset;
			history_req.user_id=localStorage.getItem('userId');
			api_req.operation="airliquid_report";
			api_req.moduleType="airliquid_report";
			api_req.api_type="web";
			api_req.access_token=localStorage.getItem('access_token');
			api_req.element_data = history_req;
      this.serverService.sendServer(api_req).subscribe((response:any) => {
            Swal.close();
	            if(response.result.status==1){
	           
	            	this.agentmap_list=response.result.data.list_data;
	            	this.offset_count = list_data.offset;
	            	this.paginationData = this.serverService.pagination({'offset':response.result.list_info.offset, 'total':response.result.list_info.total, 'page_limit' :this.pageLimit });
	            	this.recordNotFound = this.agentmap_list.length == 0 ? true : false;
	            }
               
            }, 
            (error)=>{
                //console.log(error);
            });

	  }
  get_list1(){
  }
  deleteagent_map(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        let access_token: any = localStorage.getItem('access_token');
        let admin_id: any = localStorage.getItem('admin_id');
        let api_req: any = '{"operation":"airliquid_report", "moduleType": "airliquid_report", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"delete_agentMap","agent_map_id":"' + id + '"}}';

        this.serverService.sendServer(api_req).subscribe((response: any) => {
          if (response.result.data == 1) {
            iziToast.success({
              message: "Deleted successfully",
              position: 'topRight'
            });
            this.getagentmap_list({});
          }

        },
          (error) => {
            //console.log(error);
          });
      }
    })
  }

}
